<template>
<div class="smartFarming pubPage3">
  <div class="pubPage3Left">
    <img src="../../assets/img/zhnm.png">
    <div class="text">
      <div>智慧农贸</div>
      <div>信息化、数字化、规范化</div>
    </div>
  </div>
  <div class="pubPage3Right">
    <div class="title">案例详情</div>
    <div class="pubP">农贸市场不断发展变革，客户购买环境不断优化升级，市场方经营策略不断革新。传统农贸市场已经不能适应目前市场竞争状况！</div>
    <div class="pubP">从生产源头、物流单位、供应商、经销商、分销商、消费者全流程追溯信息。</div>
    <div class="xingbiao">
      <div class="xingbiaoList">自营线上商城</div>
      <div class="xingbiaoList">独立市场</div>
      <div class="xingbiaoList">云端总调控把控市场走向</div>
    </div>
    <div class="title" style="margin-top: 28px">关联产品</div>
    <div class="xingbiao">
      <div class="lineProduct">自营线上商城</div>
      <div class="lineProduct">独立市场</div>
      <div class="lineProduct">云端总调控把控市场走向</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "smartFarming",
}
</script>

<style scoped lang="scss">
  .pubPage3{
    width: 100%;
    height: 246px;
    display: flex;
    .pubPage3Left{
      position: relative;
      width: 293px;
      height: 100%;
      img{
        width: 293px;
        height: 100%;
      }
      .text{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        padding-left: 30px;
        padding-top: 40px;
        box-sizing: border-box;
        div{
          &:last-child{
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
    .pubPage3Right{
      flex: 1;
      height: 100%;
      padding: 30px 0 0 40px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: rgba(#000000,0.45);
      .title{
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
      }
      .pubP{
        display: block;
        margin-bottom: 15px;

      }
      .xingbiao{
        display: flex;
        align-content: center;
        .xingbiaoList{
          position: relative;
          margin-right: 20px;
          margin-left: 18px;
          &::before{
            content: "";
            position: absolute;
            display: block;
            left: -15px;
            top: 3px;
            width: 10px;
            height: 10px;
            background-image: url("../../assets/img/xingbiao.png");
            background-size: 10px 10px;
          }
        }
        .lineProduct{
          position: relative;
          margin-right: 53px;
        }

      }

    }
  }
</style>
