<template>
  <div class=" pubPage3">
    <div class="pubPage3Left">
      <img src="../../assets/img/sqds.png">
      <div class="text">
        <div>社区电商</div>
        <div>线上团购+线下自提</div>
      </div>
    </div>
    <div class="pubPage3Right">
      <div class="title">案例详情</div>
      <div class="pubP">交易平台集中在社区，利用社区用户之间的信任关系，电子商务交易的成功率和速度都较高</div>
      <div class="pubP">用户在社区中可以享受电子商务服务，省去了在其它购物平台上选择和购买的麻烦，节省了个人成本</div>
      <div class="xingbiao">
        <div class="xingbiaoList">自营线上商城</div>
        <div class="xingbiaoList">独立市场</div>
        <div class="xingbiaoList">合理的价值链</div>
      </div>
      <div class="title" style="margin-top: 28px">关联产品</div>
      <div class="xingbiao">
        <div class="lineProduct">收银称重一体称</div>
        <div class="lineProduct">社区电商小程序</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "retailers",
}
</script>

<style scoped>

</style>
