<template>
  <div class=" pubPage3">
    <div class="pubPage3Left">
      <img src="../../assets/img/jzxtb.png">
      <div class="text">
        <div>记账系统</div>
        <div>可管理客户，查看客户账单</div>
      </div>
    </div>
    <div class="pubPage3Right">
      <div class="title">案例详情</div>
      <div class="pubP">可对客户进行精细化管理，并可将企业的新品和活动信息发送给有需求的客户，帮助客户及时了解动态</div>
      <div class="pubP">对客户账单进行记账、对账、收款、回款</div>
      <div class="xingbiao">
        <div class="xingbiaoList">账单管理</div>
        <div class="xingbiaoList">公司组织信息完善</div>
        <div class="xingbiaoList">客户资源管理</div>
      </div>
      <div class="title" style="margin-top: 28px">关联产品</div>
      <div class="xingbiao">
        <div class="lineProduct">牧予客情管理小程序</div>
        <div class="lineProduct">管理后台</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bookkeeping",
}
</script>

<style scoped>

</style>
