<template>
  <div>
    <!-- 内容区域 -->
    <div class="HomeContent">
      <div class="pageList page1">
        <div class="banner">
          <div class="imgBox">
            <img src="../assets/img/page1.png">
          </div>
        </div>
        <div class="box boxContent">
          <p class="bigTitle">牧予智慧生活</p>
          <p class="titleDetail">平台为商户赋能，增添销售渠道，集合社区线上流量至平台中与用户深度绑定</p>
          <div class="search">
            <input placeholder="搜产品"/>
            <img src="../assets/img/search.png">
          </div>
          <div class="page1Main">
            <div class="page1Top">
              <div class="topLeft">
                <div class="title">
                  <img src="../assets/img/rm.png">
                  <span>热门产品</span>
                </div>
                <div class="boxList">
                  <div class="list">
                    <p>信发系统</p>
                    <div>终端广告一键配置</div>
                  </div>
                  <div class="list">
                    <p>收银系统</p>
                    <div>升级门店智慧化经营</div>
                  </div>
                  <div class="list">
                    <p>物业系统</p>
                    <div>覆盖住宅、办公、商业等物业管理需要</div>
                  </div>
                  <div class="list">
                    <p>记账系统</p>
                    <div>企业财务管理新模式</div>
                  </div>
                </div>
              </div>
              <div class="topRight">
                <div class="title">
                  <img src="../assets/img/cpdt.png">
                  <span>产品动态</span>
                </div>
                <div class="boxList" ref="RboxList">
                  <div class="listContent" ref="listContent">
                    <div class="list">市场公示屏（农残、价格、证照）</div>
                    <div class="list">自助服务终端（溯源查询、充值）</div>
                    <div class="list">市场大数据屏（交易、客流、多维度）</div>
                    <div class="list">商户评价体系</div>
                    <div class="list">市场公示屏（农残、价格、证照）</div>
                    <div class="list">自助服务终端（溯源查询、充值）</div>
                    <div class="list">市场大数据屏（交易、客流、多维度）</div>
                    <div class="list">商户评价体系</div>
                  </div>

                </div>

              </div>
            </div>
            <div class="page1Bottom">
              <div>
                <img src="../assets/img/ba1.png">
              </div>
              <div>
                <img src="../assets/img/ba2.png">
              </div>
              <div>
                <img src="../assets/img/ba3.png">
              </div>
              <div>
                <img src="../assets/img/ba4.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pageList page2">
        <div class="box">
          <div class="bigTitle">牧予科技所有产品</div>
          <div class="product">
            <div class="menuList">
              <div :class="{act:actIndex == 1,preAct:preAct==1}" @click="changePage2(1,'cashierSystem')">
                <img src="../assets/img/syxt.png">
                <span>收银系统</span>
              </div>
              <div :class="{act:actIndex == 2,preAct:preAct==2}" @click="changePage2(2, 'accountingSystem')">
                <img src="../assets/img/jzxt.png">
                <span>记账系统</span>
              </div>
              <div :class="{act:actIndex == 3,preAct:preAct==3}" @click="changePage2(3, 'lettersSystem')">
                <img src="../assets/img/xf.png">
                <span>信发系统</span>
              </div>
              <div :class="{act:actIndex == 4,preAct:preAct==4}" @click="changePage2(4, 'propertySystem')">
                <img src="../assets/img/wy.png">
                <span>物业系统</span>
              </div>
              <div :class="{act:actIndex == 5,preAct:preAct==5}" @click="changePage2(5,'omSystem')">
                <img src="../assets/img/yw.png">
                <span>运维系统</span>
              </div>
              <div :class="{act:actIndex == 6,preAct:preAct==6}" @click="changePage2(6,'gatewaySystem')">
                <img src="../assets/img/mh.png">
                <span>门户网站</span>
              </div>
              <div :class="{act:actIndex == 7,preAct:preAct==7}" @click="changePage2(7,'peopleSystem')">
                <img src="../assets/img/ry.png">
                <span>人员管理</span>
              </div>
              <div :class="{act:actIndex == 8,preAct:preAct==8}" @click="changePage2(8,'splitSystem') ">
                <img src="../assets/img/xtcf.png">
                <span>系统拆分</span>
              </div>
            </div>
            <div class="productDetail">
              <component v-bind:is="currentTabComponent"></component>
            </div>
          </div>
        </div>
      </div>
      <div class="pageList page3">
        <div class="bigTitle">全面、专业、智能的服务和解决方案</div>
        <div class="page3Mian">
          <div class="page3Content">
            <div class="page3Menus">
              <div :class="{act:menuIndex==1}" @click="menuIndex  = 1,page3Component='smartFarming'">智慧农贸</div>
              <div :class="{act:menuIndex==2}" @click="menuIndex  = 2,page3Component='property'">物业管理</div>
              <div :class="{act:menuIndex==3}" @click="menuIndex  = 3,page3Component='retailers'">社区电商</div>
              <div :class="{act:menuIndex==4}" @click="menuIndex  = 4,page3Component='cashier'">收银系统</div>
              <div :class="{act:menuIndex==5}" @click="menuIndex  = 5,page3Component='bookkeeping'">记账系统</div>
              <div :class="{act:menuIndex==6}" @click="menuIndex  = 6,page3Component='letterSending'">信发系统</div>
            </div>
            <div class="page3Components">
              <component v-bind:is="page3Component"></component>
            </div>
          </div>
        </div>
        <div class="smallBox">
          <div class="box">
            <div class="listBox">
              <div class="title">智慧农贸</div>
              <p>商户独立运营</p>
              <p>数据实时上传</p>
            </div>
            <div class="listBox">
              <div class="title">小程序</div>
              <p>打造私域流量</p>
              <p>小程序商城</p>
            </div>
            <div class="listBox">
              <div class="title">大数据</div>
              <p>支付聚合</p>
              <p>一卡通/储值管理</p>
            </div>
            <div class="listBox">
              <div class="title">智能零售</div>
              <p>收银系统</p>
              <p>社区团购</p>
            </div>
            <div class="listBox">
              <div class="title">企业</div>
              <p>企业管理客户</p>
              <p>工作更高效</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pageList page4">
        <div class="box">
          <div class="bigTitle">全方位一体化的服务体系</div>
          <div class="smallTilte">省时、省力、更省心</div>
          <div class="lx">
            <img src="../assets/img/lx.png"/>
            <div class="detailBox">
              <div class="title">联系我们</div>
              <p>电话：023-41666182</p>
              <p>地址：重庆市璧山区璧渝路339号附6号</p>
            </div>
          </div>
        </div>

      </div>
      <div class="pageList page5">
        <div class="box">
          <div class="left">
            <div class="title">产品应用</div>
            <p>专注于新零售、线上线下一体化、信息化</p>
            <div class="btn">
              <span>了解更多</span>
              <img src="../assets/img/right.png"/>
            </div>
          </div>
          <img src="../assets/img/page5.png"/>
        </div>
      </div>
      <div class="pageList page6">
        <div class="box">
          <div class="list">
            <img src="../assets/img/icon1.png">
            <span>个性化定制服务</span>
          </div>
          <div class="list">
            <img src="../assets/img/icon2.png">
            <span>1V1大客户服务</span>
          </div>
          <div class="list">
            <img src="../assets/img/icon3.png">
            <span>提供多种解决方案</span>
          </div>
          <div class="list">
            <img src="../assets/img/icon4.png">
            <span>7*24小时服务</span>
          </div>
        </div>
      </div>
      <div class="pageList page7">
        <div class="box">
          <div class="left">
            <div class="list">
              <div class="title">产品</div>
              <div class="li">信发系统</div>
              <div class="li">物业管理系统</div>
              <div class="li">收银系统</div>
              <div class="li">记账系统</div>
            </div>
            <div class="list">
              <div class="title">解决方案</div>
              <div class="li">社区团购</div>
              <div class="li">企业管理客户</div>
              <div class="li">智慧农贸</div>
              <div class="li">小程序商城</div>
            </div>
            <div class="list">
              <div class="title">用户指南</div>
              <div class="li">提供的服务</div>
              <div class="li">联系客服</div>
            </div>
          </div>
          <div class="right">
            <p>法律声明及隐私政策</p>
            <p>©2021muyu-tech.cn版权所有 </p>
            <p>备案号：渝ICP备2020014195号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="returnTop" v-show="scrollTop>800" @click="smoothUp"></div>
  </div>
</template>

<script>
import smartFarming from "./page3Components/smartFarming";
import property from "./page3Components/property";
import retailers from "./page3Components/retailers";
import cashier from "./page3Components/cashier";
import bookkeeping from "./page3Components/bookkeeping";
import letterSending from "./page3Components/letterSending";
import cashierSystem from "./productDetail/cashierSystem";
import accountingSystem from "./productDetail/accountingSystem";
import lettersSystem from "./productDetail/lettersSystem";
import propertySystem from "./productDetail/propertySystem";
import omSystem from "./productDetail/omSystem";
import gatewaySystem from "./productDetail/gatewaySystem";
import peopleSystem from "./productDetail/peopleSystem";
import splitSystem from "./productDetail/splitSystem";

export default {
  props: {},
  components: {
    smartFarming,
    property,
    retailers,
    cashier,
    bookkeeping,
    letterSending,
    cashierSystem,
    accountingSystem,
    lettersSystem,
    propertySystem,
    omSystem,
    gatewaySystem,
    peopleSystem,
    splitSystem,
  },
  data() {
    return {
      actIndex: 1,
      preAct: 0,
      menuIndex: 1,
      currentTabComponent: 'cashierSystem',
      page3Component: 'smartFarming',
      scrollTop: 0,
      mysetInterval: null,
      setIntervalTwo: null

    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
    this.mysetInterval = setInterval(() => {
      if (this.menuIndex >= 6) {
        this.menuIndex = 0;
      }
      this.menuIndex++;
      switch (this.menuIndex) {
        case 1:
          this.page3Component = 'smartFarming';
          break;
        case 2:
          this.page3Component = 'property';
          break;
        case 3:
          this.page3Component = 'retailers';
          break;
        case 4:
          this.page3Component = 'cashier';
          break;
        case 5:
          this.page3Component = 'bookkeeping';
          break;
        case 6:
          this.page3Component = 'letterSending';
          break;
        default:
          this.page3Component = 'smartFarming';
          break;
      }
    }, 5000);
    this.initNotif()

  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
    clearInterval(this.mysetInterval)
    clearInterval(this.setIntervalTwo)
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
    clearInterval(this.mysetInterval)
    clearInterval(this.setIntervalTwo)
    next()
  },
  methods: {

    initNotif() {
      let ph = this.$refs.RboxList.clientHeight;
      let ch = this.$refs.listContent.clientHeight;
      if(ph < ch){
        let bz = 0;
       this.setIntervalTwo  =  setInterval(()=>{
          if(bz < -1*ch+ph){
            bz = 0;
          }
         this.$refs.listContent.style.transform=`translateY(${bz}px)`;
          bz -= 1.5;
        },100)
      }
    },
    changePage2(index, name) {
      if (index != this.actIndex) {
        this.preAct = this.actIndex;
        this.actIndex = index;
        this.currentTabComponent = name;
      }

    },
    smoothUp() {
      // 获取当前 offsetTop
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 0) {
        document.body.scrollTop = this.scrollTop - 50
        document.documentElement.scrollTop = this.scrollTop - 50
        requestAnimationFrame(this.smoothUp)
      }
    },
    // 滚动监听器
    onScroll() {
      // 获取当前文档流的 scrollTop
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    },
  },
}
</script>

<style scoped lang="scss">
.returnTop {
  width: 73px;
  height: 73px;
  position: fixed;
  right: 50px;
  bottom: 262px;
  background-image: url("../assets/img/topNo.png");
  background-size: 73px 73px;
  cursor: pointer;

  &:hover {
    background-image: url("../assets/img/topYes.png");
  }
}

/* 内容区的样式 */
.HomeContent {
  background-color: white;
  min-width: 1400px;
}

.HomeContent .pageList {
  width: 100%;
  font-size: 36px;
}

.pageList {
  position: relative;

  .banner {
    width: 100%;
    height: 360px;
    background: #D4E5ED;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .imgBox {
    min-width: 1400px;
    width: 1400px;
    position: relative;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  .box {
    position: relative;
    min-width: 1400px;
    width: 1400px;
    margin: 0 auto;
  }
}

.page1 {
  padding-top: 68px;
  box-sizing: border-box;

  .banner {
    img {
      display: block;
      margin-left: auto;
    }
  }

  .boxContent {
    z-index: 2;
    font-size: 18px;
    font-weight: 400;
    color: #000000;

    .bigTitle {
      font-size: 36px;
      font-weight: 400;
      color: #000000;

    }

    .titleDetail {
      margin: 30px 0 50px 0;
    }

    .search {
      width: 480px;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 4px 5px 16px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      padding: 0 30px 0 20px;
      box-sizing: border-box;

      input {
        border: none;
        flex: 1;
        height: 100%;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
      }

      input::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #B6BECE;
      }

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

    }

    .page1Main {
      width: 1400px;
      //height: 347px;
      background: #FFFFFF;
      border: 1px solid #FAFAFA;
      box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.06);
      margin-top: 50px;
      padding: 15px 25px 20px 25px;
      box-sizing: border-box;

      .page1Top {
        display: flex;
        //align-items: center;
        .title {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #000000;

          img {
            width: 40px;
            height: 40px;
            margin-right: 15px;
          }
        }

        .topLeft {
          width: 60%;


          .boxList {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-right: 1px solid rgba(black, 0.06);
            padding-left: 55px;
            box-sizing: border-box;

            .list {
              font-weight: 400;
              color: #000000;
              width: 50%;
              margin-top: 28px;

              p {
                font-size: 14px;
                cursor: pointer;
              }

              div {
                font-size: 12px;
                opacity: 0.45;
                margin-top: 14px;
              }
              &:hover{
                p {
                  color: #3d7eff;
                }
              }

            }
          }

        }

        .topRight {
          width: 40%;
          padding-left: 25px;
          box-sizing: border-box;

          .title {
            margin-bottom: 28px;
          }

          .boxList {
            width: auto;
            height: 130px;
            overflow: hidden;

            .listContent {
              .list {
                margin-bottom: 18px;
                margin-left: 55px;
                position: relative;
                font-size: 14px;
                font-weight: 400;
                color: #000000;

                &::before {
                  content: "";
                  position: absolute;
                  left: -15px;
                  top: 10px;
                  width: 5px;
                  height: 5px;
                  background: #C1D2DD;
                }
              }
            }


          }


        }
      }

      .page1Bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        margin-top: 27px;

        div {
          max-width: 294px;
          max-height: 100px;
          overflow: hidden;

          img {
            cursor: pointer;
            width: 294px;
            height: 100px;
          }

          &:hover {
            img {
              transition: transform 1s;
              transform: scale(1.1);
            }
          }
        }


      }
    }
  }

}

.page2 {
  margin: 80px 0;

  .bigTitle {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }

  .product {
    display: flex;
    min-height: 600px;
    height: 620px;
    overflow-y: auto;

    .menuList {
      position: relative;
      border-right: 1px solid rgba(black, 0.06);
      padding: 0 20px;
      box-sizing: border-box;


      div {
        display: flex;
        align-items: center;
        height: 60px;
        line-height: 60px;
        width: 100%;
        margin-bottom: 20px;
        cursor: pointer;

        &:hover {
          span {
            color: #3D7EFF;
          }

        }

        //&:first-child{
        //  margin-top: 20px;
        //}
        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .line {
        position: absolute;
        top: 0;
        right: -2px;
        width: 3px;
        height: 40px;
        background: #3D7EFF;
        opacity: 1;
        transition: height 0.5s;

      }

      @keyframes myfirst {
        from {
          height: 0
        }
        to {
          height: 60px
        }
      }
      @keyframes myfirst1 {
        from {
          height: 60px
        }
        to {
          height: 0
        }
      }

      .act {
        position: relative;

        span {
          color: #3D7EFF;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -22px;
          width: 3px;
          height: 0;
          background: #3D7EFF;
          opacity: 1;
          transform: translateY(-50%);
          animation: myfirst 0.5s;
          animation-fill-mode: forwards;
          animation-direction: alternate;
        }
      }

      .preAct {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -22px;
          width: 3px;
          height: 0;
          background: #3D7EFF;
          opacity: 1;
          transform: translateY(-50%);
          animation: myfirst1 0.5s;
          animation-fill-mode: forwards;
          animation-direction: alternate;
        }
      }
    }

    .productDetail {
      flex: 1;
      padding-left: 20px;
      box-sizing: border-box;
    }
  }

}

.page3 {
  .bigTitle {
    width: 1400px;
    position: relative;
    text-align: center;
    margin: 0 auto;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 60px;
  }

  .page3Mian {
    position: relative;
    width: 100%;
    height: 390px;
    background: linear-gradient(90deg, #242933, #25375B, #242933);
    padding: 40px 0;

    .page3Content {
      width: 1400px;
      position: relative;
      margin: 0 auto;

      .page3Menus {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          flex: 1;
          text-align: center;
          padding: 0 0 15px 0;
          cursor: pointer;
        }

        @keyframes myfirst2 {
          from {
            width: 0
          }
          to {
            width: 100%
          }
        }

        .act {
          position: relative;
          color: #3D7EFF;
          //border-bottom: 3px solid #3D7EFF;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0%;
            height: 3px;
            background: #3D7EFF;
            animation: myfirst2 5s;
          }
        }
      }

      .page3Components {
        width: 100%;
        margin-top: 20px;
        background: #EBF1F4;
        border: 1px solid #F8FDFF;
        box-shadow: -1px -3px 40px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .smallBox {
    width: 100%;
    margin-top: 60px;

    .box {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      align-content: center;
      justify-content: space-between;

      .listBox {
        width: 272px;
        height: 300px;
        padding: 60px 0 0 30px;
        box-sizing: border-box;
        background: #3d7eff;
        cursor: pointer;

        &:nth-child(1) {
          background-image: url("../assets/img/small1.png");
        }

        &:nth-child(2) {
          background-image: url("../assets/img/small2.png");
        }

        &:nth-child(3) {
          background-image: url("../assets/img/small3.png");
        }

        &:nth-child(4) {
          background-image: url("../assets/img/small4.png");
        }

        &:nth-child(5) {
          background-image: url("../assets/img/small5.png");
        }

        &:hover {
          background-image: url("../assets/img/actImg.png");
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          margin-bottom: 30px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 0.8;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.page4 {
  margin-top: 80px;

  .bigTitle {
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }

  .smallTilte {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #3E7FFF;
    margin-top: 20px;
  }

  .lx {
    margin-top: 40px;
    position: relative;

    img {
      width: 1180px;
      height: 460px;
    }

    .detailBox {
      position: absolute;
      top: 0;
      right: 0;
      width: 341px;
      height: 200px;
      background: #FFFFFF;
      border: 1px solid #FAFAFA;
      box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.06);
      padding: 30px 20px 0 25px;
      box-sizing: border-box;

      .title {
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        border-bottom: 1px solid rgba(#000000, 0.06);
        position: relative;
        padding: 12px 0;
        margin-bottom: 30px;

        &::before {
          content: "";
          width: 3px;
          height: 40px;
          background: #000000;
          position: absolute;
          left: -25px;
          top: 10px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }
}

.page5 {
  width: 100%;
  //height: 200px;
  background-color: #3E7FFF;

  .box {
    display: flex;

    .left {
      flex: 1;
      padding-left: 100px;
      box-sizing: border-box;

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        margin: 40px 0 18px 0;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 32px;
      }

      .btn {
        width: 160px;
        height: 42px;
        background: #2465E5;
        border: 1px solid #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0 30px;
        line-height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 27px;

        img {
          width: 8px;
          height: 12px;
        }
      }
    }

    image {
      width: 980px;
      height: 100%;
    }
  }
}

.page6 {
  margin: 40px 0;

  .box {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    color: #000000;

    .list {
      width: 20%;
      display: flex;
      align-items: center;
      border-right: 1px solid #E5E5E5;
      text-align: center;

      &:last-child {
        border: none;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

}

.page7 {
  width: 100%;
  background: #2E3033;
  padding-top: 60px;

  .box {
    display: flex;
    justify-content: space-between;
    padding: 0 100px;
    box-sizing: border-box;

    .left {
      width: 80%;
      display: flex;
      border-right: 1px solid rgba(#ffffff, 0.1);
      margin-bottom: 20px;

      .list {
        margin-right: 150px;

        .title {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          margin-bottom: 30px;
        }

        .li {
          font-size: 12px;
          font-weight: 400;
          color: rgba(#ffffff, 0.8);
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    }

    .right {
      p {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.8;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
