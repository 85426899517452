<template>
  <div class=" productDetail">
    <div class="productImg">
      <p>人员管理系统</p>
      <p>专业打造配套人员管理系统</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          人员管理平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">人员管理后台系统</div>
            <p>统一、高效管理部门、员工、职位</p>
            <p>一键查看店铺、市场、物业明细</p>
            <div class="btns">
              <div>统一管理</div>
              <div>高效快速</div>
              <div>实时查看</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "peopleSystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/wyMain.png");
}
</style>
