<template>
  <div class=" pubPage3">
    <div class="pubPage3Left">
      <img src="../../assets/img/wygl.png">
      <div class="text">
        <div>物业管理</div>
        <div>便民生活、社区服务</div>
      </div>
    </div>
    <div class="pubPage3Right">
      <div class="title">案例详情</div>
      <div class="pubP">依据互联网+理念，为物业提供一站式综合系统及APP开发方案</div>
      <div class="pubP">政务服务+便民生活，将物业社区服务和居民生活联系在一起</div>
      <div class="xingbiao">
        <div class="xingbiaoList">巡检监控</div>
        <div class="xingbiaoList">商户管理</div>
        <div class="xingbiaoList">合同审批</div>
      </div>
      <div class="title" style="margin-top: 28px">关联产品</div>
      <div class="xingbiao">
        <div class="lineProduct">物业管理APP</div>
        <div class="lineProduct">缴费查询公众号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "property",
}
</script>

<style scoped>

</style>
