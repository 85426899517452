<template>
  <div class="cashierSystem productDetail">
    <div class="productImg">
      <p>收银系统</p>
      <p>专业打造配套智慧收银系统</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          智慧收银
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">智慧农贸电子秤(PC秤)</div>
            <p>Windows/安卓、极简交互</p>
            <p>操作简单易上手，界面清晰整洁</p>
            <div class="btns">
              <div>实时获取重量</div>
              <div>自定义编辑价格</div>
              <div>在线挂单</div>
              <div>电子小票</div>
              <div>电子价签</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">门店零售电子收银秤</div>
            <p>Windows/安卓、极简交互</p>
            <p>界面逻辑清晰、结构严谨，操作简易</p>
            <div class="btns">
              <div>实时获取重量</div>
              <div>自定义编辑价格</div>
              <div>会员选择</div>
              <div>会员赊销体系</div>
              <div>称重设备绑定</div>
              <div>电子小票</div>
              <div>电子价签</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">收款音箱/收款码牌</div>
            <p>收款语音播报</p>
            <p>统一设计模板</p>
            <p>支付聚合（微信、支付宝、龙支付、云闪付）</p>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="pubBoxTitle">
          收银平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">收银管理后台系统</div>
            <p>会员管理、商品管理、库存管理</p>
            <p>销售管理、用户管理、系统管理</p>
            <p>支持微信账号登录</p>
            <div class="btns">
              <div>操作简单</div>
              <div>统一管理</div>
              <div>方便快捷</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">商户收款公众号</div>
            <p>实时收款消息通知</p>
            <p>可设置店员权限，添加店员</p>
            <p>随时查看店铺收款统计、账单明细</p>
            <div class="btns">
              <div>方便快捷</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">社区电商小程序</div>
            <p>针对社区打造的电商小程序</p>
            <div class="btns">
              <div>网上下单</div>
              <div>送货上门</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">智慧农贸小程序商城</div>
            <p>商品支持自主管理</p>
            <div class="btns">
              <div>操作灵活</div>
              <div>统一管理</div>
              <div>数据价值</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cashierSystem",
}
</script>

<style scoped lang="scss">
.productDetail {
  display: flex;
}

.productImg {
  width: 300px;
  min-height: 600px;
  background: url("../../assets/img/syMian.png");
  background-size: 300 rpx 600px;
  padding: 40px 0 0 30px;
  box-sizing: border-box;
  margin-right: 60px;

  p {
    &:nth-of-type(1) {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
    }

    &:nth-of-type(2) {
      font-size: 14px;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 20px;
    }
  }


}

.productDetailBox {
  width: calc(100% - 300px);

  .contentBox {
    margin-bottom: 30px;

    .pubBoxTitle {
      font-size: 16px;
      font-weight: 400;
      color: #3D7EFF;
      margin-bottom: 20px;
    }

    .contentList {

      display: flex;

      .ListBox {
        width: 33.33%;
        cursor: pointer;

        .listTilte {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 12px;
        }
        &:hover{
          .listTilte{
            color: #3D7EFF;
          }
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          margin-bottom: 8px;
        }

        .btns {
          display: flex;
          flex-wrap: wrap;

          div {
            height: 22px;
            line-height: 22px;
            background: #FFFFFF;
            opacity: 1;
            border: 1px solid #E5E5E5;
            font-size: 12px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.45);
            padding: 0 6px;
            box-sizing: border-box;
            margin-bottom: 8px;
            margin-right: 8px;
          }
        }
      }

    }
  }
}

</style>
