<template>
  <div class="productDetail">
    <div class="productImg">
      <p>门户网站</p>
      <p>个性化定制门户网站、营销产品</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          Web平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">门户网站</div>
            <p>个性化定制用户界面</p>
            <p>支持免费登录注册</p>
            <div class="btns">
              <div>商务简约</div>
              <div>逻辑清晰</div>
              <div>操作简单</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="pubBoxTitle">
          营销中心
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">营销后台管理系统</div>
            <p>支持产品新增、产品编辑、产品购买功能</p>
            <p>支持客户管理、新增、编辑客户功能</p>
            <p>后台banner管理功能</p>
            <p>收款银行、收款码管理功能</p>
            <div class="btns">
              <div>操作简单</div>
              <div>统一管理</div>
              <div>方便快捷</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gatewaySystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/mhMain.png");
}
</style>
