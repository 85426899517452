<template>
  <div class="accountingSystem productDetail">
    <div class="productImg">
      <p>记账系统</p>
      <p>企业财务管理新模式</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          记账平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">客情管理小程序</div>
            <p>支持ios/Android操作系统</p>
            <p>操作简单易上手，界面清晰整洁</p>
            <p>组织管理、账单记录、通讯录、企业认证</p>
            <div class="btns">
              <div>手机操作</div>
              <div>统一管理</div>
              <div>随时沟通</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">客情管理后台</div>
            <p>操作简单易上手，界面简约干净</p>
            <p>统一管理客户、供应商</p>
            <p>账单管理、组织管理、系统管理</p>
            <div class="btns">
              <div>操作简单</div>
              <div>统一管理</div>
              <div>方便快捷</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "accountingSystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/jzMain.png");
}
</style>
