<template>
  <div class=" productDetail">
    <div class="productImg">
      <p>系统拆分</p>
      <p>专业、独立、安全、高效</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          后台系统拆分
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">线上商城系统</div>
            <p>支持商品、商户、用户管理</p>
            <p>对订单进行统一查看、管理</p>
            <p>总览商品、商户的销售数据</p>
            <div class="btns">
              <div>统一管理</div>
              <div>高效快速</div>
              <div>实时查看</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">会员系统</div>
            <p>对会员进行管理</p>
            <p>支持积分设置、积分记录浏览</p>
            <p>管理和查看积分订单</p>
            <div class="btns">
              <div>统一管理</div>
              <div>灵活设置</div>
              <div>角色管理</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">溯源系统</div>
            <p>生产管理规范化、标准化</p>
            <p>发现产品质量缺陷时，可迅速实现缺陷食品召回</p>
            <p>保护消费者权益</p>
            <p>提高管理效率</p>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">农残检测系统</div>
            <p>界面逻辑清晰、操作简洁</p>
            <p>查看所有产品的检测数据，一目了然、高效放心</p>
            <p>可新增检测项目或者产品、支持导出信息等功能</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "splitSystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/cfMain.png");
}
</style>
