<template>
  <div class=" pubPage3">
    <div class="pubPage3Left">
      <img src="../../assets/img/xfxt.png">
      <div class="text">
        <div>信发系统</div>
        <div>帮助企业进行多终端广告管理</div>
      </div>
    </div>
    <div class="pubPage3Right">
      <div class="title">案例详情</div>
      <div class="pubP">总部一键配置，终端实时更新</div>
      <div class="pubP">远程管理、智能分屏、权限设置、定时任务，更多功能</div>
      <div class="xingbiao">
        <div class="xingbiaoList">一键配置</div>
        <div class="xingbiaoList">多种模板</div>
        <div class="xingbiaoList">实时发布</div>
      </div>
      <div class="title" style="margin-top: 28px">关联产品</div>
      <div class="xingbiao">
        <div class="lineProduct">信发系统管理后台</div>
        <div class="lineProduct">信发系统APP</div>
        <div class="lineProduct">大屏配置APK</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "letterSending",
}
</script>

<style scoped>

</style>
