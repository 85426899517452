<template>
  <div class="productDetail">
    <div class="productImg">
      <p>运维系统</p>
      <p>日常运行维护管理</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          运维平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">运维后台管理系统</div>
            <p>提供全周期的专业服务</p>
            <p>稳定、安全、高效</p>
            <p>满足企业各类场景的服务需求</p>
            <div class="btns">
              <div>集体管理</div>
              <div>数据管理</div>
              <div>环境管理</div>
              <div>服务器管理</div>
              <div>应用配置</div>
              <div>成员管理</div>
              <div>应用安装</div>
              <div>角色管理</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "omSystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/ywMain.png");
}
</style>
