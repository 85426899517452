<template>
  <div class=" pubPage3">
    <div class="pubPage3Left">
      <img src="../../assets/img/syxtb.png">
      <div class="text">
        <div>收银系统</div>
        <div>线上线下一体化</div>
      </div>
    </div>
    <div class="pubPage3Right">
      <div class="title">案例详情</div>
      <div class="pubP">收银、店务、会员、促销、支付等业务一体化的门店终端</div>
      <div class="pubP">解决线上线下一体化、多渠道资源整合</div>
      <div class="xingbiao">
        <div class="xingbiaoList">自营线上商城</div>
        <div class="xingbiaoList">独立市场</div>
        <div class="xingbiaoList">云端总调控把控市场走向</div>
      </div>
      <div class="title" style="margin-top: 28px">关联产品</div>
      <div class="xingbiao">
        <div class="lineProduct">独立管理后台</div>
        <div class="lineProduct">收银系统</div>
        <div class="lineProduct">社区电商小程序</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cashier",
}
</script>

<style scoped>

</style>
