<template>
  <div class="productDetail">
    <div class="productImg">
      <p>物业系统</p>
      <p>覆盖住宅、办公和商业等物业管理需要</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          物业平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">物业管理后台系统</div>
            <p>后台菜单，细节管理</p>
            <p>待办提醒、提升工作效率</p>
            <p>智能巡检、安全放心</p>
          </div>
          <div class="ListBox">
            <div class="listTilte">物业管理App</div>
            <p>实时接收市场公告</p>
            <p>实时管理合同业务</p>
            <p>费用监管、智能巡检</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "propertySystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/wyMain.png");
}
</style>
