<template>
  <div class="productDetail">
    <div class="productImg">
      <p>信发系统</p>
      <p>终端广告一键配置</p>
    </div>
    <div class="productDetailBox">
      <div class="contentBox">
        <div class="pubBoxTitle">
          信发大屏机
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">智慧农贸电子秤(PC秤)</div>
            <p>Windows/安卓、极简交互</p>
            <p>界面逻辑清晰、结构严谨，操作简易</p>
          </div>
          <div class="ListBox">
            <div class="listTilte">信发系统APK</div>
            <p>配置APK</p>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="pubBoxTitle">
          信发平台
        </div>
        <div class="contentList">
          <div class="ListBox">
            <div class="listTilte">信发后台管理系统</div>
            <p>Windows/安卓、极简交互</p>
            <p>多终端一键发布，内容实时更新</p>
            <div class="btns">
              <div>终端设备远程操作</div>
              <div>终端、节目管理</div>
              <div>终端设备状态查询</div>
            </div>
          </div>
          <div class="ListBox">
            <div class="listTilte">信发系统App</div>
            <p>多终端一键发布，内容实时更新</p>
            <p>操作简单易上手，界面清晰整洁</p>
            <div class="btns">
              <div>设备终端实时掌控</div>
              <div>节目在线预览</div>
              <div>节目移动发布</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lettersSystem",
}
</script>

<style scoped lang="scss">
.productImg{
  background: url("../../assets/img/xfMain.png");
}
</style>
